<!-- <template>
  <keep-alive :include="include">
    <router-view />
  </keep-alive>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ConstructionMachinery",
  computed: {
    ...mapState(["cachePages"]),
    /**
     * 需要缓存的页面名称列表
     */
    include() {
      return this.cachePages.map((menu) => menu.name);
    },
  },
};
</script> -->

<template>
  <SubLayout
    parentName="safeDutyInsuredManagement"
    :navData="navData"
  ></SubLayout>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import SubLayout from "@/layout/SubLayout";
import * as safeDutyInsured from "@/router/modules/safeDutyInsuredManagement";

export default {
  // `name`与路由保持一致
  name: "safeDutyInsuredManagement",
  components: {
    SubLayout,
  },
  data() {
    return {};
  },
  computed: {
    // ...mapState(["cachePages"]),
    navData() {
      return Object.values(safeDutyInsured).filter((val) => !val.meta.hideMenu);
    },
  },
  methods: {
    ...mapMutations(["setCachePages"]),
  },
  beforeDestroy() {
    // 从store `cachePages` 清除所有子页面
    // let { cachePages, navData } = this;
    // cachePages = cachePages.filter(
    //   (menu) => !navData.some((nav) => nav.name === menu.name)
    // );
    // this.setCachePages(cachePages);
  },
};
</script>
